import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, connectSearchBox, connectHits, connectInfiniteHits } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import qs from 'qs';
import './App.css'
// import 'instantsearch.css/themes/satellite-min.css'
import ReactGA from 'react-ga';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col, ListGroup, Form} from 'react-bootstrap';

ReactGA.initialize('G-TE6408DB5H');
ReactGA.pageview(window.location.pathname + window.location.search);

const searchClient = algoliasearch(
    '2SVQ3R41KQ',
    'b0d7b1576381de5e38991834e7e00223'
);
const translations = {
   meeting: 'ישיבת הנהלה מתאריך ',
   search: " חפש במסמכים...",
  rulesDoc: 'תקנון'

}

const createURL = state => `?${qs.stringify(state)}`;

const searchStateToUrl = ( location, searchState) =>
    searchState ? `${location.pathname}${createURL(searchState)}` : '';

const urlToSearchState = ({ search }) => qs.parse(search.slice(1));
const DEBOUNCE_TIME = 400;

const CustomHits = connectInfiniteHits((props) => {
  const { hits, query } = props;
  return (hits.map(hit => <Hit hit={hit} query={query}/>))
})

const CardHits = connectHits(({ hits }) => {
  const h = hits.slice(0, 3);
  return(
      <ListGroup as={'ul'} className={'list-group-horizontal-xl list-unstyled'}>
        {h.map(hit => <Hit hit={hit} classes={'flex-fill m-2 list-unstyled'} />)}
      </ListGroup>
  )
})

const CustomSearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
    <Form className={'col-7 col-md-5 m-auto text-center'}>
      <Form.Control className={'mb-5 form-control-lg'} placeholder={translations.search}
        onChange={event => refine(event.currentTarget.value)}
                    value={currentRefinement}
        />
    </Form>
);

function Hit(props) {
  const {date, permalink, type} = props.hit
  const { classes, query} = props;
  let d = DateTime.fromSeconds(parseInt(date));
  const prefix = type === 'rulesDoc' ? translations.rulesDoc : translations.meeting
  const link = query ? `${permalink}?text=${query}` : permalink;
  return (
      <ListGroup.Item className={classes} action href={link}>{`${prefix}  ${d.setLocale('en-GB').toLocaleString()}`}</ListGroup.Item>
  );
}

const SearchBox = connectSearchBox(CustomSearchBox)

const App = () => {
  let {location, history} = window
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [debouncedSetState, setDebouncedSetState] = useState(null);

  const onSearchStateChange = updatedSearchState => {
    clearTimeout(debouncedSetState);

    setDebouncedSetState(
        setTimeout(() => {
          history.pushState(
              updatedSearchState,
              null,
              searchStateToUrl(location, updatedSearchState)
          );
        }, DEBOUNCE_TIME)
    );

    setSearchState(updatedSearchState);
  };

  const hasSearch =  searchState && searchState.query;
  return (
      <>
{/*
        <Configure hitsPerPage={4}
                   analytics={false}
                   enablePersonalization={true}
                   distinct />
*/}
        <Container fluid={true}>
          <Row>
            <Col>
        <header className={'row static-top justify-content-center border-bottom'}>
          <h1 className={'mb-5 text-center'}>אגודה קהילתית מלכיה</h1>
        </header>
              <nav className={'row navbar navbar-light navbar-expand-lg bg-light'}>
                  <ul className={'navbar-nav'}>
                    <li className={'nav-item'}>
                      <a className={'text-right nav-link fs-4'} href={'/'}>חיפוש</a>
                    </li>
                    <li className={'nav-item'}>
                      <a className={'text-right nav-link fs-4'} href={'/takanonim/agoda.html'}>תקנון</a>
                    </li>
                  </ul>
              </nav>
        <div className={'row search pt-lg-5'}>
          <Container className={'justify-content-center mt-lg-5'} fluid={true}>
          <h2 className={'text-center'}>חיפוש בפרוטוקלים ותקנון</h2>
          <InstantSearch
              indexName="malkiya"
              searchClient={searchClient}
              searchState={searchState}
              onSearchStateChange={onSearchStateChange}
              createURL={createURL}
          >
              <SearchBox />
            <div className={'row d-flex m-auto justify-content-center'}>
              <div className={'col-xl-auto col-7'}>
                {hasSearch && <ListGroup as={'ul'} className={'text-center scrollbar scrollbar-primary'}>
                <CustomHits query={searchState.query}/>
              </ListGroup>}
                {!hasSearch && <CardHits />}
              </div>
            </div>
          </InstantSearch>
          </Container>
        </div>
         </Col>
          </Row>
        </Container>
      </>
  );
}


Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default App;
